import React, {FC, ReactElement} from "react";
import {Box} from "@mui/material";
import ShopBudgetList from "./partner/components/bookings/bookingsSettings/shop/ShopBudgetList";
import {usePartnerContext} from "./partner/shared/PartnerContext";
import Section from "../components/form/Section";
import {useTranslation} from "react-i18next";

const sectionStyle = {
    container: {
        padding: "0px",
        paddingTop: "22px",
    }
}

const ShopPackageHistory: FC = (): ReactElement => {
    const {partner} = usePartnerContext()
    const {t} = useTranslation('bookings')

    return (
        <Box>
            <Section
                title={t('shopPackage.history.title')}
                styles={sectionStyle}
            >
                <ShopBudgetList partner={partner}/>
            </Section>
        </Box>
    )
}
export default ShopPackageHistory;
