import {apiVersion, getApiClient} from '../ApiUtils';
import {ShopBudgetPage, ShopPackageResource} from "./ShopPackageResources";

export function getShopPackageByPartnerCwid(partnerCwid: string): Promise<ShopPackageResource> {
    return getApiClient().then(client => client.get<ShopPackageResource>(
        `/partners/${partnerCwid}/shop-package`,
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}))
        .then(response => response.data)
}

export function getShopBudgetHistoryByPartnerCwid(
    partnerCwid: string,
    page: number = 0,
    size: number = 20
): Promise<ShopBudgetPage> {
    return getApiClient()
        .then(client =>
            client.get<ShopBudgetPage>(
                `/partners/${partnerCwid}/shop-package/history`,
                {
                    baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion,
                    params: { page, size },
                }
            )
        )
        .then(response => response.data);
}
