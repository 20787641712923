import {Box} from "@mui/material";
import React, {FC} from "react";
import RelocationTypes from "./RelocationTypes";
import PricesAndDetails from "./PricesAndDetails";
import PaymentTypes from "./PaymentTypes";
import BookingTypes from "./BookingTypes";
import MaxPartners from "pages/partner/components/bookings/modal/form/MaxPartners";
import {Booking} from "api/booking/BookingResource";
import RequestTypes from "pages/partner/components/bookings/modal/form/RequestTypes";

export type BookingFormProps = {
    booking: Booking | undefined;
}

const BookingForm: FC<BookingFormProps> = ({booking}: BookingFormProps) => {
    return (
        <Box sx={{maxHeight: '70vh', display: 'flex', flexDirection: 'column'}}>
            <Box sx={{flexGrow: 1, overflowY: 'auto', overflowX: 'hidden'}}>
                <BookingTypes/>
                <PricesAndDetails booking={booking}/>
                <RequestTypes/>
                <RelocationTypes/>
                <PaymentTypes/>
                <MaxPartners/>
            </Box>
        </Box>
    )
}

export default BookingForm
