import React, {FC, ReactElement, SyntheticEvent, useEffect, useState} from "react";
import {Theme} from "@mui/material";
import Modal from "../../../../../components/form/Modal";
import {useBookingContext, useBookingModal} from "../BookingContext";
import UmzugEasyTabs from "../../../../../components/tabs/EmzugEasyTabs";
import UmzugEasyTab from "../../../../../components/tabs/EmzugEasyTab";
import TabPanel from "../../../../relocationRequest/relocationRequestDetail/TabPanel";
import {useTheme} from "@mui/styles";
import {useTranslation} from "react-i18next";
import BookingForm from "./form/BookingForm";
import TourForm from "./tours/TourForm";
import {Formik, useFormikContext} from "formik";
import {BookingModalContext} from "./BookingModalContext.model";
import {useInitBookingModalContext} from "./BookingModalContext";
import BookingFormButtons from "./components/BookingFormButtons";
import {BookingModalForm, bookingToBookingEditForm, schema} from "../BookingContext.model";
import BookingModalTitle from "./components/BookingModalTitle";
import BookingScheduler from "./BookingScheduler";
import {allPaymentTypes, allRequestTypes} from "api/relocationRequest/RelocationRequestDetail";

const BookingModalContent: FC = () => {
    const theme = useTheme<Theme>()
    const {isOpen, booking} = useBookingModal()
    const {t} = useTranslation('bookings')
    const {actions, modal, isSaving} = useBookingContext()
    const [currentTabValue, setCurrentTabValue] = useState<number>(modal?.type === 'tour' ? 1 : 0)
    const [addedInitialNewTour, setAddedInitialNewTour] = useState<boolean>(false)

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setCurrentTabValue(newValue)
    }
    const formik = useFormikContext<BookingModalForm>()
    const ctx = useInitBookingModalContext(formik);

    useEffect(() => {
        if (modal?.type === 'tour' && modal.action === 'add' && !addedInitialNewTour) {
            setAddedInitialNewTour(true)
            ctx.modalActions.addTour()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal?.type, modal?.action])

    return (
        <BookingModalContext.Provider value={ctx}>
            <Modal isOpen={isOpen} title={<>{booking != null && (<BookingModalTitle/>)}</>}
                   width={'980px'}>
                <>
                    <UmzugEasyTabs value={currentTabValue} onChange={handleChange}
                                   sx={{
                                       borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
                                       alignItems: 'end'
                                   }}>
                        {
                            ['details', 'tours', 'validity'].map((type, index) => (
                                <UmzugEasyTab key={type} aria-controls={`booking-edit-${type}`}
                                              label={t(`booking.edit.tabs.${type}`)}
                                              value={index}
                                              sx={{
                                                  minWidth: 'min-content',
                                                  padding: '2px',
                                                  marginRight: '40px',
                                                  maxHeight: '40px',
                                                  minHeight: '40px'
                                              }}/>
                            ))
                        }
                    </UmzugEasyTabs>
                    <TabPanel index={0} value={currentTabValue}>
                        <BookingForm booking={booking}/>
                    </TabPanel>
                    <TabPanel index={1} value={currentTabValue}>
                        <TourForm/>
                    </TabPanel>
                    <TabPanel index={2} value={currentTabValue}>
                        <BookingScheduler booking={booking}/>
                    </TabPanel>
                    <BookingFormButtons
                        save={{
                            translationKey: 'booking.edit.buttons.save',
                            onClick: () => !isSaving && ctx.modalActions.save(),
                            disabled: !ctx.isValid
                        }}
                        remove={currentTabValue === 0 && !(modal?.action === 'add' && modal.type === 'booking') ? {
                            translationKey: 'booking.edit.buttons.removeBooking',
                            onClick: () => modal?.bookingId != null && actions.onBookingRemove(modal.bookingId)
                        } : undefined}
                        cancel={{
                            translationKey: 'booking.edit.buttons.cancel',
                            onClick: () => actions.onCloseModal()
                        }}
                    />
                </>
            </Modal>
        </BookingModalContext.Provider>
    )
}

const BookingModal: FC = (): ReactElement => {
    const {booking, modal} = useBookingModal();

    const initialValues = bookingToBookingEditForm(booking);
    if (modal?.type === 'booking' && modal.action === 'add') {
        initialValues.requestTypes = allRequestTypes;
        initialValues.paymentTypes = allPaymentTypes;
    }

    return (
        <>
            {booking != null &&
                <Formik initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(values: BookingModalForm) => console.log('values', values)}>
                    <BookingModalContent/>
                </Formik>
            }
        </>
    );
};

export default BookingModal;
