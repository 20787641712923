// pages
import Overview from "../pages/Overview";
import Algotest from "../pages/testing/algotest/Algotest";
import ApiClients from "pages/admin/ApiClients";
import ApiDocumentation from "pages/admin/ApiDocumentation";
import TestAccounts from "../pages/testing/TestAccounts";
import MainData from "../pages/partner/components/MainData";
import Bookings from "../pages/partner/components/Bookings";
import Requests from "../pages/partner/components/Requests";
import UndistributedRequests from "../pages/partner/components/UndistributedRequests";
import Documents from "../pages/partner/components/Documents";
import Bills from "../pages/partner/components/Bills";
import Administration from "../pages/Administration";
import BookingTest from "../pages/testing/bookingtest/BookingTest";
import OverviewReport from "pages/reports/overview/OverviewReport";

// other
import {FC, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Partners from "../pages/Partners";
import Dashboard from '../pages/partner/components/dashboard/Dashboard';
import PartnerDetails from "../pages/partner/PartnerDetails";
import RelocationRequests from "../pages/RelocationRequests";
import InventoryList from "pages/relocationRequest/invntoryListDetail/InventoryList";
import PartnersReport from "pages/reports/partners/PartnersReport";
import ShopPackageHistory from "pages/ShopPackageHistory";

// interface
export type Route = {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC,
    subRoutes?: SubRoute[]
    subRoutesInContent?: SubRoute[],
    hideInNav?: boolean
}

export type SubRoute = {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC,
    nestedComponent?: FC,
    hideInNav?: boolean
}

export enum Routes {
    MAIN_PAGE = '/',
    RELOCATION_REQUESTS_PAGE = '/relocation-requests',
    PARTNERS_LIST_PAGE = '/partner',
    REPORTS_PAGE = '/reports/overview',
    TESTING_PAGE = '/testing',
    ADMIN_PAGE = '/admin/api-clients',
    ADMINISTRATION_PAGE = '/administration',
}

export enum PartnerRoutes {
    DASHBOARD = '/partner/:cwid/dashboard',
    MAIN_DATA = '/partner/:cwid/main-data',
    BOOKINGS = '/partner/:cwid/bookings',
    SHOP_PACKAGE_HISTORY = '/partner/:cwid/shop-package-history',
    REQUESTS = '/partner/:cwid/requests',
    NOT_RECEIVED_REQUESTS = '/partner/:cwid/not-received-requests',
    DOCUMENTS = '/partner/:cwid/documents',
    BILLS = '/partner/:cwid/bills'
}

export enum ReportRoutes {
    OVERVIEW = '/reports/overview',
    PARTNER = '/reports/partner'
}

export enum TestingRoutes {
    ALGOTEST = '/testing',
    BOOKING_TEST = '/testing/perimeter',
    TEST_ACCOUNTS = '/testing/test-accounts'
}

export enum AdminRoutes {
    API_CLIENTS = '/admin/api-clients',
    API_DOCUMENTATION = '/admin/api-documentation'

}

export const routes: Array<Route> = [
    {
        key: 'overview-route',
        title: 'nav.overview',
        path: Routes.MAIN_PAGE,
        enabled: true,
        component: Overview,
    },
    {
        key: 'relocation-requests-route',
        title: 'nav.relocationRequests',
        path: Routes.RELOCATION_REQUESTS_PAGE,
        enabled: true,
        component: RelocationRequests,
        subRoutes: [
            {
                key: 'relocation-requests-inventory-list-route',
                title: 'nav.relocationRequestsInventoryList',
                path: '/relocation-requests/:id/inventory-list',
                enabled: true,
                hideInNav: true,
                component: InventoryList
            },
            {
                key: 'relocation-requests-photo-inventory-route',
                title: 'nav.relocationRequestsPhotoInventory',
                path: '/relocation-requests/:id/photo-inventory',
                enabled: true,
                hideInNav: true,
                component: InventoryList
            }
        ]
    },
    {
        key: 'partners-list-route',
        title: 'nav.partners',
        path: Routes.PARTNERS_LIST_PAGE,
        enabled: true,
        component: Partners,
        subRoutesInContent: [
            {
                key: 'partners-dashboard-route',
                title: 'nav.dashboard',
                path: PartnerRoutes.DASHBOARD,
                enabled: true,
                component: PartnerDetails,
                nestedComponent: Dashboard
            },
            {
                key: 'partners-main-data-route',
                title: 'nav.baseData',
                path: PartnerRoutes.MAIN_DATA,
                enabled: true,
                component: PartnerDetails,
                nestedComponent: MainData
            },
            {
                key: 'partners-subscription-and-post-code-route',
                title: 'nav.bookings',
                path: PartnerRoutes.BOOKINGS,
                enabled: true,
                component: PartnerDetails,
                nestedComponent: Bookings
            },
            {
                key: 'partners-shop-package-history',
                title: 'nav.shopPackageHistory',
                path: PartnerRoutes.SHOP_PACKAGE_HISTORY,
                enabled: true,
                component: PartnerDetails,
                nestedComponent: ShopPackageHistory
            },
            {
                key: 'partners-requests-route',
                title: 'nav.requests',
                path: PartnerRoutes.REQUESTS,
                enabled: true,
                component: PartnerDetails,
                nestedComponent: Requests
            },
            {
                key: 'partners-not-received-requests-route',
                title: 'nav.notReceivedRequests',
                path: PartnerRoutes.NOT_RECEIVED_REQUESTS,
                enabled: true,
                component: PartnerDetails,
                nestedComponent: UndistributedRequests
            },
            {
                key: 'partners-documents-route',
                title: 'nav.documents',
                path: PartnerRoutes.DOCUMENTS,
                enabled: true,
                component: PartnerDetails,
                nestedComponent: Documents
            },
            {
                key: 'partners-bills-route',
                title: 'nav.bills',
                path: PartnerRoutes.BILLS,
                enabled: true,
                component: PartnerDetails,
                nestedComponent: Bills
            }
        ]
    },
    {
        key: 'reports-route',
        title: 'nav.reports',
        path: Routes.REPORTS_PAGE,
        enabled: true,
        component: OverviewReport,
        subRoutes: [
            {
                key: 'overview-report-route',
                title: 'nav.overviewReport',
                path: ReportRoutes.OVERVIEW,
                enabled: true,
                component: OverviewReport
            },
            {
                key: 'partners-report-route',
                title: 'nav.partnersReports',
                path: ReportRoutes.PARTNER,
                enabled: true,
                component: PartnersReport
            }
        ]
    },
    {
        key: 'testing-route',
        title: 'nav.testing',
        path: Routes.TESTING_PAGE,
        enabled: true,
        component: Algotest,
        subRoutes: [
            {
                key: 'testing-algotest-route',
                title: 'nav.algotest',
                path: TestingRoutes.ALGOTEST,
                enabled: true,
                component: Algotest
            },
            {
                key: 'testing-perimeter-route',
                title: 'nav.perimeter',
                path: TestingRoutes.BOOKING_TEST,
                enabled: true,
                component: BookingTest
            },
            {
                key: 'testing-test-accounts-route',
                title: 'nav.testAccounts',
                path: TestingRoutes.TEST_ACCOUNTS,
                enabled: true,
                component: TestAccounts
            }
        ]
    },
    {
        key: 'admin-route',
        title: 'nav.admin',
        path: Routes.ADMIN_PAGE,
        enabled: true,
        component: ApiClients,
        subRoutes: [
            {
                key: 'admin-api-clients-route',
                title: 'nav.apiClients',
                path: AdminRoutes.API_CLIENTS,
                enabled: true,
                component: ApiClients
            },
            {
                key: 'admin-api-documentation-route',
                title: 'nav.apiDocumentation',
                path: AdminRoutes.API_DOCUMENTATION,
                enabled: true,
                component: ApiDocumentation
            }
        ]
    },
    {
        key: 'administration-route',
        title: 'nav.administration',
        path: Routes.ADMINISTRATION_PAGE,
        enabled: false,
        component: Administration
    }
]

export function useRoutePath(route: SubRoute, value?: { [key: string]: string }): string {
    if (value == null) {
        return route.path
    }
    const keys = Object.keys(value);
    return `${route.path}`
        .split('/')
        .map(item => (keys.indexOf(item) !== -1) ? value[item] : item)
        .join('/')
}

// hook which return active parent and child route
export function useActiveRoute(): { parent: Route | undefined, child: SubRoute | undefined } {
    const location = useLocation();
    const [parentRoute, setParentRoute] = useState<Route>()
    const [childRoute, setChildRoute] = useState<SubRoute>()

    const pathRegex = (routePath: string): RegExp => {
        // we check path with path variable e.g. id, and also with / at the end
        // eslint-disable-next-line no-useless-escape
        const rgx = `${routePath}`.replaceAll(/\//g, "\/")
            .replaceAll(new RegExp(":[a-zA-Z0-9-]+", "g"), "[:a-zA-Z0-9.-]+");
        // eslint-disable-next-line no-useless-escape
        return new RegExp(`^${rgx}(\/|)$`, 'g')
    }

    const getSubRoutes = (route?: Route) =>
        [...(route?.subRoutes || []), ...(route?.subRoutesInContent || [])]

    useEffect(() => {
        const getActiveRoute = (routes: Route[]): Route | undefined => {
            return routes.find(route => {
                const paths: RegExp[] = [pathRegex(route.path)];
                const subRoutes = getSubRoutes(route)
                if (subRoutes.length > 0) {
                    subRoutes.forEach(subRoute => {
                        paths.push(pathRegex(subRoute.path))
                    })
                }
                return paths.find(path => path.test(location.pathname)) != null;
            });
        }
        const getActiveParentRoute = () => getActiveRoute(routes)
        const getActiveChildRoute = (parent: Route | undefined) => getActiveRoute(getSubRoutes(parent))

        const activeParentRoute = getActiveParentRoute();
        setParentRoute(activeParentRoute);
        setChildRoute(getActiveChildRoute(activeParentRoute));
    }, [location])

    return {parent: parentRoute, child: childRoute}
}
