import {styled, TableCell, tableCellClasses, TableRow} from "@mui/material";
import React from "react";
import {TableHeader, TableModel} from "./model/UmzugEasyTableModel";
import {useSortedHeaders} from "./util/UmzugEasyTableHooks";
import {useClassNames} from 'hooks/ClassNamesHook';
import {useTableContext} from './UmzugEasyTableContext';
import {useTranslation} from "react-i18next";
import {DefaultRenderer} from "components/table/util/UmzugEasyCellRenderers";

export interface UmzugEasyTableRowProps<T> extends TableModel<T> {
    onClick?: (row: T) => void,
    onLastRowRendered?: () => void
}

type RowProps<T> = {
    headers: TableHeader<T>[],
    row: T,
    onClick?: (row: T) => void
}
type CellProps<T> = {
    header: TableHeader<T>,
    row: T
}

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd).theme-default': {
        backgroundColor: theme.palette.background.paper,
    },
    '&:nth-of-type(even).theme-default': {
        backgroundColor: theme.palette.common.white,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&.clickable': {
        cursor: 'pointer'
    },
    '&.active': {
        backgroundColor: `${theme.palette.action.active} !important`
    },
    '&.active > td': {
        borderColor: `${theme.palette.action.active} !important`
    }
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        paddingBottom: 7,
        paddingTop: 7,
        color: theme.palette.text.secondary,
        fontWeight: 400,
        position: "relative",
    },
    [`&.${tableCellClasses.body}.theme-default`]: {
        borderBottom: `1px solid ${theme.palette.background.paper}`,
    },
    [`&.${tableCellClasses.body}.theme-default:not(:last-child)`]: {
        borderRight: `1px solid ${theme.palette.background.paper}`,
    },
    [`&.${tableCellClasses.body}.theme-simple`]: {
        borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    }
}));

const Cell = <T extends unknown>(props: CellProps<T>) => {
    const {value, cellRenderer} = props.header;
    const {t} = useTranslation(props.header.translateFile || 'common');
    if (cellRenderer) { // We can use JSX.Element instead of plain text
        return (<>{cellRenderer(props.row, t)}</>)
    } else {
        return DefaultRenderer((value ? value(props.row, t) : ''))
    }
}

const Row = <T extends unknown>(props: RowProps<T>) => {

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        if (props.onClick) {
            e.stopPropagation()
            props.onClick(props.row)
        }
    }
    const { theme, isRowActive } = useTableContext();

    const classNames = useClassNames({
        clickable: props.onClick != null,
        active: isRowActive != null && isRowActive(props.row)
    })


    return (
        <StyledTableRow onClick={handleOnClick} className={classNames} classes={{ root: `theme-${theme} table-row` }}>
            {
                props.headers.map((item, index) => (
                    <StyledTableCell sx={item.sxColumn} key={index} classes={{ root: `theme-${theme} cell-${item.key}`}}>
                        <Cell header={item} row={props.row}/>
                    </StyledTableCell>
                ))
            }
        </StyledTableRow>
    )
}

const UmzugEasyTableRows = <T extends unknown>(props: UmzugEasyTableRowProps<T>): JSX.Element => {
    const sortedHeaders = useSortedHeaders(props.content.headers);
    return (
        <>
            {props.content.rows.map((row, index) => (
                <Row row={row} headers={sortedHeaders} key={index} onClick={props.onClick}></Row>
            ))}
        </>
    )
}
export default UmzugEasyTableRows
