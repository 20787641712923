import React, {useMemo, useState} from "react";
import {TableHeader} from "./model/UmzugEasyTableModel";
import {styled, TableCell, tableCellClasses, TableHead, TableRow, TableSortLabel, Theme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSortedHeaders} from "./util/UmzugEasyTableHooks";
import {useTableContext} from './UmzugEasyTableContext';
import {SxProps} from "@mui/system";

type UmzugEasyTableHeaderProps<T> = {
    groupHeaders?: TableHeader<T>[],
    headers: TableHeader<T>[],
    sxGroupHeaders?: SxProps<Theme>,
    sxHeaders?: SxProps<Theme>,
    stickyGroupHeader: boolean
};
type HeaderRowProps<T> = {
    header: TableHeader<T>,
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}.theme-default`]: {
        fontSize: 14,
        paddingBottom: 7,
        paddingTop: 7,
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        whiteSpace: "nowrap",
    },
    [`&.${tableCellClasses.head}.theme-default:not(:last-child)`]: {
        borderRight: `1px solid ${theme.palette.background.paper}`,
    },
    [`&.${tableCellClasses.head}.theme-simple`]: {
        color: theme.palette.text.secondary,
        paddingRight: "1rem",
        fontSize: "14px",
        fontStyle: "italic",
        paddingBottom: 7,
        paddingTop: 7,
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    }
}));

const HeaderRow = <T extends unknown>(props: HeaderRowProps<T>) => {
    const {header} = props;
    const {t} = useTranslation(header.translateFile);
    const {theme} = useTableContext();

    const [active, setActive] = useState<boolean>(false);
    const [direction, setDirection] = useState<"asc" | "desc" | undefined>(undefined);

    const onDirectionChange = () => {
        setDirection(prevState => {
            let newDirection: "asc" | "desc" | undefined;
            if (prevState === "desc") {
                newDirection = "asc";
            } else if (prevState === "asc") {
                newDirection = undefined;
            } else {
                newDirection = "desc";
            }
            setActive(newDirection !== undefined);
            if (header.onSortChange) {
                header.onSortChange(header.key, newDirection);
            }
            return newDirection;
        });
    }

    return (
        <StyledTableCell
            classes={{head: `theme-${theme} header-${props.header.key}`}}
            colSpan={header.colSpan}
        >
            {
                header.onSortChange && (<TableSortLabel
                active={active}
                direction={direction}
                onClick={onDirectionChange}
            >
                {header.nameParam ? t(header.name, {year: header.nameParam}) : t(header.name)}
            </TableSortLabel>)}
            {!header.onSortChange && (header.nameParam ? t(header.name, {year: header.nameParam}) : t(header.name))}
        </StyledTableCell>
    )
}

const UmzugEasyTableHeader = <T extends unknown>(props: UmzugEasyTableHeaderProps<T>) => {
    const stableGroupHeaders = useMemo(() => props.groupHeaders || [], [props.groupHeaders]);
    const sortedGroupHeaders = useSortedHeaders(stableGroupHeaders);
    const sortedHeaders = useSortedHeaders(props.headers);
    const sxGroupHeaders: SxProps<Theme> = props.stickyGroupHeader
        ? {
            ...props.sxGroupHeaders,
            position: 'sticky',
            top: 0,
        }
        : props.sxGroupHeaders ?? {}
    const sxHeaders: SxProps<Theme> = props.stickyGroupHeader
        ? {
            ...props.sxGroupHeaders,
            position: 'sticky',
            top: 50,
        }
        : props.sxGroupHeaders ?? {}
    return (
        <TableHead style={ props.sxGroupHeaders ? {
        position: 'sticky',
            zIndex: '1000'
    } : {}}
>
            {   sortedGroupHeaders.length > 0 &&
                (<TableRow
                    sx={sxGroupHeaders}
                >
                {
                    sortedGroupHeaders
                        .map(header => (
                            <HeaderRow header={header} key={header.key}/>
                        ))
                }
            </TableRow>)
            }
            <TableRow
                sx={sxHeaders}
            >
                {
                    sortedHeaders
                        .map(header => (
                            <HeaderRow header={header} key={header.key}/>
                        ))
                }
            </TableRow>
        </TableHead>
    )
}
export default UmzugEasyTableHeader;
