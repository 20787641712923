import {
    initPageDetails,
    UndistributedRequestsActionsProps,
    UndistributedRequestsContext,
    UndistributedRequestsContextProps
} from "./UndistributedRequestsContext.model";
import {useContext, useEffect, useState} from "react";
import {Lead, LeadGenerationResult, LeadGenerationResultType, PartnerLead} from "api/lead/LeadResources";
import {AlertProps} from "components/feedback/Alert";
import {useTranslation} from "react-i18next";
import {PageDetails} from "api/Page";
import {getLeadGenerationResults, getLeads} from "api/lead/LeadApi";
import {
    anonymizeRelocationRequest,
    getRelocationRequestDetail
} from "api/relocationRequest/RelocationRequestsApi";
import {DeletionConfirmationModalProps} from "components/form/DeletionConfirmationModal";
import {RelocationRequestDetail} from "api/relocationRequest/RelocationRequestDetail";

export function useInitUndistributedRequestsContext(partnerCwid: string | undefined): UndistributedRequestsContextProps {
    const [loading, setLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<AlertProps | undefined>(undefined)
    const [rejectedLeads, setRejectedLeads] = useState<LeadGenerationResult[]>([])
    const [pageDetails, setPageDetails] = useState<PageDetails>(initPageDetails)
    const [openLeadDetailsId, setOpenLeadDetailsId] = useState<string | undefined>(undefined);
    const [partnerLeads, setPartnerLeads] = useState<PartnerLead[]>([]);
    const [isAnonymizingRelocationRequest, setIsAnonymizingRelocationRequest] = useState<boolean>(false)
    const [anonymizationConfirmationModal, setAnonymizationConfirmationModal] = useState<DeletionConfirmationModalProps | undefined>(undefined)
    const [leadDetailsByRequestId, setLeadDetailsByRequestId] = useState<RelocationRequestDetail>()
    const [leadsByRequestId, setLeadsByRequestId] = useState<Map<string, Lead[]>>(new Map())

    const {t} = useTranslation('undistributedRequests')

    const showAlert = (translationKey: string) => {
        setAlert({
            color: 'error',
            open: true,
            text: t(`rejectedLeads.alerts.${translationKey}`),
            handleClose: () => setAlert(undefined)
        })
    }
    const hideAnonymizationConfirmationModalModal = () => setAnonymizationConfirmationModal(undefined)

    const anonymize = () => {
        if (openLeadDetailsId) {
            setIsAnonymizingRelocationRequest(true)
            anonymizeRelocationRequest(openLeadDetailsId)
              .then(relocationRequestDetailResponse => {
                  setPartnerLeads(prevState =>
                    prevState.map(lead =>
                      (openLeadDetailsId === lead.relocationRequest.requestId) ?
                        {...lead, relocationRequest: relocationRequestDetailResponse} : lead)
                  )
              })
              .catch(() => showAlert('anonymizationError'))
              .finally(() => {
                  setIsAnonymizingRelocationRequest(false)
                  hideAnonymizationConfirmationModalModal()
              })
        }
    }

    const loadRejectedLeads = (pageForLoad: number) => {
        if (partnerCwid) {
            if (pageForLoad === 0) {
                setLoading(true)
            }
            getLeadGenerationResults(partnerCwid, LeadGenerationResultType.REJECTED, pageForLoad, 20)
                .then(rejectedLeads => {
                    if (pageForLoad === 0) {
                        setRejectedLeads(rejectedLeads._embedded?.leads || []);
                    } else {
                        setRejectedLeads(prevState => [...prevState, ...(rejectedLeads._embedded?.leads || [])]);
                    }
                    setPageDetails(rejectedLeads.page)
                })
                .catch(() => showAlert('getRejectedLeadsError'))
                .finally(() => setLoading(false))
        }
    }

    const actions: UndistributedRequestsActionsProps = {
        onLoadNextPage: () => {
            const {number, totalPages} = pageDetails
            if (number + 1 < totalPages) {
                loadRejectedLeads(number + 1);
            }
        },
        onOpenRejectedLeadDetails: (requestId: string) => {
            if (requestId) {
                setLoading(true)
                getRelocationRequestDetail(requestId)
                  .then(relocationRequestDetailResponse => {
                      setLeadDetailsByRequestId(relocationRequestDetailResponse)
                  })
                  .catch(() => showAlert('getLeadsError'))
                  .finally(() => setLoading(false))
                if (!leadsByRequestId.has(requestId)) {
                    getLeads(requestId)
                      .then((leads) => setLeadsByRequestId(prevState => new Map(prevState).set(requestId, leads.leads)))
                      .catch(() => showAlert('getLeadsError'))
                      .finally(() => setLoading(false))
                }
                setOpenLeadDetailsId(prevState => prevState === requestId ? undefined : requestId)
            }
        },
        onAnonymizeRelocationRequest: () => {
            setAnonymizationConfirmationModal({
                labels: {
                    title: t('requestDetails.detailsTab.modals.anonymize.title'),
                    description: t('requestDetails.detailsTab.modals.anonymize.description'),
                    buttonYes: t('requestDetails.detailsTab.modals.anonymize.buttonYes'),
                    buttonNo: t('requestDetails.detailsTab.modals.anonymize.buttonNo'),
                },
                confirm: () => anonymize(),
                cancel: () => hideAnonymizationConfirmationModalModal()
            });
        }
    }

    useEffect(() => {
        if (partnerCwid) {
            loadRejectedLeads(0)
        }
        // eslint-disable-next-line
    }, [partnerCwid])

    return {
        leadsByRequestId,
        rejectedLeads,
        leadDetailsByRequestId,
        openLeadDetailsId,
        pageDetails,
        loading,
        alert,
        actions,
        anonymizationConfirmationModal,
        isAnonymizingRelocationRequest,
        partnerLeads
    }
}

export function useUndistributedRequestsContext(): UndistributedRequestsContextProps {
    return useContext(UndistributedRequestsContext)
}