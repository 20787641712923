import { UmzugEasyTableProps } from "../../UmzugEasyTable";
import { getUmzugEasyTableProps } from "../SchemaUtil";
import { TableSchema } from "../SchemaModel";
import { useEffect, useState } from "react";
import { ContractResource } from 'api/contract/ContractResourcePage';
import { ContractFilters } from 'api/contract/ContractFilters';
import { getContracts } from 'api/contract/ContractsApi';
import { BoldRenderer } from '../../util/UmzugEasyCellRenderers';
import { useTranslation } from 'react-i18next';


export const useContractLoader = (filters: ContractFilters) => {
    const [contracts, setContracts] = useState<ContractResource[]>([])
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(-1);
    const [count, setCount] = useState(0);
    const [tableProps, setTableProps] = useState<UmzugEasyTableProps<ContractResource>>(getContractTableSchema());

    const loadContracts = (pageForLoad: number, filtersForLoad: ContractFilters) => {
        getContracts(filtersForLoad, pageForLoad).then(contracts => {
            if (pageForLoad === 0) {
                setContracts(contracts._embedded?.contracts || []);
            } else {
                setContracts(prevState => [...prevState, ...(contracts._embedded?.contracts || [])]);
            }
            setCount(contracts.page.totalElements)
            setPageCount(contracts.page.totalPages)
            setPage(pageForLoad)
        });
    }

    const loadNextPage = () => {
        if (page + 1 < pageCount) {
            loadContracts(page + 1, filters);
        }
    }

    useEffect(() => {
        loadContracts(0, filters)
        // eslint-disable-next-line
    }, []);

    function useServiceTypeDict(): Map<string, string> {
        const {t} = useTranslation('partner');
        const dict = new Map<string, string>();

        ['RELOCATION_BASIC', 'RELOCATION_OTHER_LEAD_TYPES', 'RELOCATION_LEAD_SHOP', 'RELOCATION_TOP', 'RELOCATION_SHOP_PACKAGE']
          .forEach(key => dict.set(key, t(`mainData.contracts.serviceTypes.${key}`)));

        return dict;
    }

    const dict = useServiceTypeDict();

    useEffect(
        () => setTableProps(getContractTableSchema(contracts, dict)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [contracts]
    )

    return {tableProps, count, page, pageCount, filters, loadNextPage}
}

const getSchema =(serviceTypeDic?: Map<string, string>) : TableSchema<ContractResource>[] => [
    {key: 'serviceEndDate', value: (row) => row.endDate || ''},
    {key: 'serviceStartDate', value: (row) => row.startDate || ''},
    {key: 'service', cellRenderer: (data) => BoldRenderer(serviceTypeDic?.get(data.name) || '')},
]

export const getContractTableSchema = (contracts?: ContractResource[], serviceTypeDic?: Map<string, string>): UmzugEasyTableProps<ContractResource> => {
    return {
        table: getUmzugEasyTableProps<ContractResource>({
            schema: getSchema(serviceTypeDic),
            rows: contracts,
            translationPrefix: 'mainData.contracts.table',
            translationFile: 'partner'
        }),
      noDataMessageKey: 'partner:mainData.contracts.table.noData'
    }
}
